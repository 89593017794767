<template>
  <div class="container-fluid p-0 m-0 login-wrapper">
    <div class="left-bottom-img-wrapper p-0 m-0">
      <img class="left-bottom-bg" src="../assets/Login/loginLeftBG.png" />
      <img class="login-left-bg-img" src="../assets/Login/Invite-amico.svg" />
    </div>

    <div class="row m-0 login-header">
      <div class="col-4 p-0 m-0 hecto-logo">
        <span @click="gotoLandingPage">
          <img src="../assets/nav/HectocommerceLogo.svg">
        </span>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-5 col-lg-5 col-xl-5 col-sm-5"></div>  -->
      <div
        class="col-md-6 col-lg-6 col-xl-6 col-sm-12 login-form-wrapper"
      >
        <div class="row">
          <div class="text-center mx-auto">
            <p class="login-title mb-0">Let us know about you!</p>
            <p class="login-subtitle">We will get back to you !</p>
          </div>
        </div>
        <div class="row request-form">
          <div class="col-md-6">
            <label class="tree-label">Name</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :validation="'regex_alpha_numeric_space'"
              :defaultValue="userName"
              :labelStyle="'register-label'"
              :fieldName="'Name'"
              @textInputChange="getUserName($event)"
            />
          </div>
          <div class="col-md-6">
            <label class="tree-label">Email-ID</label>
            <w-text-input
              :fieldType="'email'"
              :disabled="false"
              :defaultValue="emailID"
              :validation="'regex_Email'"
              :labelStyle="'register-label'"
              :fieldName="'Email'"
              @textInputChange="geEmailID($event)"
            />
          </div>
          <div class="col-md-6">
            <label class="tree-label">Phone</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="phoneNumber"
              :labelStyle="'register-label'"
              :fieldName="'Phone'"
              :validation="'regex_mobile_number'"
              @textInputChange="getMobleNumber($event)"
            />
          </div>
          <div class="col-md-6">
            <label class="tree-label">Company Name</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="companyName"
              :labelStyle="'register-label'"
              :fieldName="'Company Name'"
              :validation="'regex_alpha_numeric_space'"
              @textInputChange="getCompanyName($event)"
            />
          </div>
          <div class="col-md-6">
            <label class="tree-label">No of Marketplace Accounts</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedmarketplace"
              :selectOption="marketPlaceOptions"
              @selectedOption="getMarketplace($event)"
            />
          </div>
          <div class="col-md-6">
            <label class="tree-label">No of Orders Per Month</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedOrders"
              :selectOption="orderPerMonthOptions"
              @selectedOption="getOrderPerMonth($event)"
            />
          </div>
          <div class="col-md-6">
            <label class="tree-label">Country</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedCountry"
              :selectOption="countryOptions"
              @selectedOption="getCountryName($event)"
            />
          </div>
          
        </div>
        <div class="col-12 p-0 m-0 mt-4 text-center submit-section">
            <b-button class="submit-demo" @click="requestForDemo()" :disabled="isRequestDetails">SUBMIT</b-button>
        </div>
      </div>
    </div>
    <div class="row p-0 m-0 copy-rights-wrapper">
      <div class="col-6 col-12 p-0 m-0 ml-auto copy-rights-content">
        Copyright © 2021 HectoCommerce, A DIATOZ Product, All rights reserved
      </div>
    </div>

<!-- Error popup -->
    <w-modal
      :trigger="alert"
      :centered="true"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      @eventClose="closeModal()"
    />

  </div>
</template>

<script>
// import Img from "../assets/Login/loginLeftBG.png"
import TextInput from "../widgets/TextInput.vue";
import Dropdown from "../widgets/Dropdown.vue";
import Modal from "../widgets/ErrorPopUp";
import { requestDemoCall } from "../Service/UserService";

export default {
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    "w-modal":Modal,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",

      userName: "",
      emailID: "",
      phoneNumber: "",
      companyName: "",

      selectedmarketplace: "",
      selectedOrders: "",
      selectedCountry: "",
      marketPlaceOptions: [
        { value: "0", text: "0" },
        { value: "1 - 3", text: "1 - 3" },
        { value: "4 - 8", text: "4 - 8" },
        { value: "More than 8", text: "More than 8" },
      ],
      orderPerMonthOptions: [
        { value: "0 - 100", text: "0 - 100" },
        { value: "100 - 500", text: "100 - 500" },
        { value: "500 - 1,000", text: "500 - 1,000" },
        { value: "1,000 - 3,000", text: "1,000 - 3,000" },
        { value: "3,000 - 5,000", text: "3,000 - 5,000" },
        { value: "5,000 - 10,000", text: "5,000 - 10,000" },
        { value: "More than 10,000", text: "More than 10,000" },
      ],
      countryOptions: [
        { value: "India", text: "India" },
        { value: "Singapure", text: "Singapure" },
      ],
    };
  },

  computed: {
    isRequestDetails() {
      if (
        !this.userName == "" &&
        !this.emailID == "" &&
        !this.phoneNumber == "" &&
        !this.companyName == "" &&
        !this.selectedmarketplace == "" &&
        !this.selectedOrders == "" &&
        !this.selectedCountry == ""
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    // get user name
    getUserName(event) {
      this.userName = event;
    },

    // get user email
    geEmailID(event) {
      this.emailID = event;
    },

    // get mobile number
    getMobleNumber(event) {
      this.phoneNumber = event;
    },

    // get company name
    getCompanyName(event) {
      this.companyName = event;
    },

    // get marketplace
    getMarketplace(event) {
      this.selectedmarketplace = event;
    },

    //   get order per month
    getOrderPerMonth(event) {
      this.selectedOrders = event;
    },

    // get country
    getCountryName(event) {
      this.selectedCountry = event;
    },

    async requestForDemo() {
      await this.$recaptchaLoaded();
      let token = await this.$recaptcha("register");
      
      let data = {
        personName: this.userName,
        personEmail: this.emailID,
        country: this.selectedCountry,
        personPhone: this.phoneNumber,
        companyName: this.companyName,
        ordersPerMonth: this.selectedOrders,
        marketplaceAccountCount: this.selectedmarketplace,
      };
      requestDemoCall(data, token)
      .then((res) => {
        this.alert = true;
        this.alertType = "Success";
        // clear input data
        this.userName="",
        this.emailID="",
        this.selectedCountry="",
        this.phoneNumber="",
        this.companyName="",
        this.selectedOrders="",
        this.selectedmarketplace=""
      })
      .catch((err) => {
        this.alert = true;
        this.alertType = "Failure";
        this.alertMsg = err.message;
      })    
    },

    closeModal() {
      this.alert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // redirect to landing page
    gotoLandingPage(){
       this.$router.push('/')
    },
  },
};
</script>

<style scoped>
.back-btn {
  font-size: 18px;
  font-family: "bebas-kai";
  color: #a3a3a3;
}
.login-wrapper {
  overflow-y: hidden;
}
.left-bottom-img-wrapper {
  position: absolute;
  bottom: 0;
}
.left-bottom-img-wrapper > .login-left-bg-img {
  position: absolute;
  top: 30%;
  left: 12%;
  width: 85%;
}

.login-wrapper {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
}
.login-wrapper::-webkit-scrollbar {
  display: none;
}
.login-header {
  padding: 1rem 6.1rem;
}
.login-header > .hecto-logo {
  font-family: "bebas-kai";
  font-size: 2rem;
  color: #464646;
}
.hecto-logo span{
    cursor: pointer;
}
.login-form-wrapper {
  padding: 1rem 2rem;
  margin-left: 40%;
}
.login-title {
  font-family: "bebas-kai";
  font-size: 3rem;
  color: #464646;
}
.login-subtitle {
  font-family: "bebas-kai";
  font-size: 1.5rem;
  color: #464646;
}

.copy-rights-wrapper {
  position: absolute;
  right: 18%;
  bottom: 6%;
}
.copy-rights-wrapper > .copy-rights-content {
  white-space: nowrap;
  font-size: 10px;
  color: #0475a1;
}
.request-form ::v-deep input {
  border: 1px solid #ededed;
  height: 35px !important;
}
.request-form ::v-deep select {
  border: 1px solid #ededed;
  height: 35px !important;
}
.request-form ::v-deep .tree-label {
  color: #464646;
  font-size: 16px;
  font-weight: bold;
}
.submit-demo {
  font-family: "Quasimoda";
  font-size: 14px;
  color: #ffffff;
  background: #029fe1;
  padding: 10px 50px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s linear;
}
.request-form ::v-deep .helo-block{
    font-size: 12px;
    position: absolute;
    z-index: 1;
}

/* for responsive design */
@media only screen and (min-width: 768px) and (max-width:991px){
    .left-bottom-img-wrapper > .login-left-bg-img{
        width: 75%;
    }
    .login-form-wrapper{
        margin-left: 48%;
    }
    .login-title{
        font-size: 2.5rem;
    }
    .request-form .tree-label{
        font-size: 11px;
    }
    .copy-rights-content{
        margin-left: 40% !important;
        white-space: normal;
    }
}
@media only screen and (min-width: 320px) and (max-width:385px){
    .login-title{
        font-size: 2.5rem !important;
    }
    .copy-rights-content{
        padding-left: 40px !important;
    }
    
}
@media only screen and (min-width:320px) and (max-width:520px){
    .login-header > .hecto-logo{
        font-size: 1.5rem;
    }
    .login-title{
        font-size: 2rem;
    }
    .login-subtitle{
        font-size: 1rem;
        margin-bottom: 0;
    }
     .submit-section{
        margin-top: 10px !important;
    }
    .left-bottom-img-wrapper > .login-left-bg-img{
        top: 44% !important;
        /* left: 20% !important; */
        width: 25% !important;
    }
    .left-bottom-bg{
        width: 42%!important;
        height: 230px!important;
    }
}
@media only screen and (min-width: 385px) and (max-width:520px){
    .copy-rights-content{
        padding-left: 40px !important;
    }
}
@media only screen and (max-width:620px){
    .copy-rights-content{
           margin-left: 0 !important;
           white-space: normal !important;
       }
}

   @media only screen and (max-width: 767.99px){
       .login-header{
           padding: 1rem 2.1rem;
       }
       .login-form-wrapper{
           margin-left: 0 !important;
       }
       .request-form{
           height: 280px !important;
           /* width: 100vw; */
           overflow-y: auto;
       }
       .left-bottom-bg{
           width: 54%;
           height: 270px;
       }
       .left-bottom-img-wrapper > .login-left-bg-img{
           top: 35%;
           width: 35%;
       }
       .copy-rights-wrapper{
           right: 0;
           width: 55%;
       }
       .copy-rights-content{
           margin-left: 0 !important;
           /* white-space: normal !important; */
       }
   }

</style>
