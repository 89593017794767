<template>
  <b-modal
    v-model="trigger"
    :id="id"
    :ok-title="okTitle"
    @ok="eventOk($event)"
    @cancel="eventCancel($event)"
    :centered="centered"
    :no-close-on-backdrop="dontCloseWhenClickedOutside"
    :no-close-on-esc="dontCloseWhenEscapePressed"
    :hide-header-close="dontShowCloseIcon"
    :ok-only="okOnly"
    :hide-footer="true"
    :hide-header="true"
  >
    
    <!-- for success msg -->
    <div class="p-3" v-if="alertType === 'Success'">
      <div class="row m-4 align-class"  >
        <img src="../assets/Login/Call center-amico.svg">
      </div>
      <div class="row m-4 mb-3 align-class">
        <p class="alert-msg m-0">
            Thanks for the information !
            <span>We will reach out soon !</span> 
        </p>
      </div>
      <div class="row justify-content-center mb-3">
        <button class="close-modal" @click="cancel">CLOSE</button>
      </div>
    </div>
    <!-- for failure msg -->
    <div class="row m-4 align-class"  v-if="alertType === 'Failure'">
        <div class="failure-msg">
            <img
            v-if="alertType === 'Failure'"
            :style="getIconStyle(alertType)"
            :src="getImage(alertType)"
            />
            <p class="my-5">{{alertMsg}}</p>
            <button class="close-modal" @click="cancel">CLOSE</button>
        </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: [
    "trigger",
    "text",
    "centered",
    "dontCloseWhenClickedOutside",
    "dontCloseWhenEscapePressed",
    "dontShowCloseIcon",
    "alert",
    "alertMsg",
    "alertType",
  ],
  methods: {
    eventOk(event) {
      this.$emit("eventOk", event);
    },

    getIconStyle(alert) {
      switch (alert) {
        case "Success":
          return "background-color: #47d764;border-radius: 100%; padding: 2px;";
        case "Failure":
          return "height:50px;width:50px";
      }
    },

    cancel() {
      this.$emit("eventClose");
    },
    getImage(image) {
      var images = require.context("../assets/alert-msg/", false, /\.svg$/);
      return images("./" + image + ".svg");
    },
  },
};
</script>

<style>
.Modal-head-text {
  color: white;
  font-size: 13px;
  float: left;
}
.modal-content {
  width: 100%;
  border-radius: 15px !important;
}
.modal-close {
  padding: 6px !important;
  color: #fff;
  /* background: #0071c1; */
}
.modal-body {
  padding: 0;
}

.modal-backdrop {
  opacity: 0.5 !important;
}
.align-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-key {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #191d24;
}
.alert-msg {
  font-family: "bebas-Kai";
  font-weight: bold;
  font-size: 2.2rem;
  font-weight: normal;
  text-align: center;
  color: #464646;
}
.alert-msg span{
    font-size: 30px;
}
/*  */
.close-modal{
    background: #029FE1;
    color: #fff;
    border: 1px solid #005D84;
    font-size: 14px;
    width: 178px;
    height: 48px;
    border-radius: 5px;
}
.failure-msg{
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
